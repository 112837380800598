import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Link } from 'gatsby'
import { cn } from '../../lib/helpers'
import * as linkStyle from '../../styles/components/links.module.css'
import Icon from '../icons'
import { Image } from '../Image'

const createGradientBackground = (gradientData) => {
  if (!gradientData.colors || gradientData.colors.length === 0) {
    return null
  }
  const { gradientDirection, colors } = gradientData

  const gradientColors = colors.map(({ color, colorPoint }) => {
    return colorPoint !== null ? `${color.hex} ${colorPoint}%` : color.hex
  }).join(', ')

  return `linear-gradient(${gradientDirection}, ${gradientColors})`
}

const handleMouseEnter = (event) => {
  const target = event.target.parentNode

  if (!target.classList.contains('mediumCard')) {
    return
  }

  cardHover(target)
}

const cardHover = (hoverOn) => {
  const dataIdx = parseInt(hoverOn.getAttribute('data-idx'))
  let bigCard = null
  let direction = null

  if (dataIdx === 1 || dataIdx === 2 || dataIdx === 12) { // Setting the direction according to the medium card number in the patterns const
    direction = 'Left'
    bigCard = findSibling(hoverOn, 'previousElementSibling', 'bigCard')
  } else if (dataIdx === 4 || dataIdx === 5) {
    direction = 'Right'
    bigCard = findSibling(hoverOn, 'nextElementSibling', 'bigCard')
  }

  applyClasses(hoverOn, bigCard, direction)

  const closestMediumCard = direction === 'Right'
    ? findSibling(hoverOn, 'nextElementSibling', 'mediumCard', bigCard, 'bigCard')
    : findSibling(hoverOn, 'previousElementSibling', 'mediumCard', bigCard, 'bigCard')

  if (closestMediumCard) {
    closestMediumCard.classList.add(styles['movement' + direction])
  }
}

const findSibling = (startElement, direction, targetClass, stopElement = null, excludeClass = null) => {
  let sibling = startElement[direction]
  while (sibling && sibling !== stopElement) {
    if (excludeClass && sibling.classList.contains(excludeClass)) {
      sibling = sibling[direction]
      continue
    }
    if (sibling.classList.contains(targetClass)) {
      return sibling
    }
    sibling = sibling[direction]
  }
  return null
}

const applyClasses = (hoverOn, bigCard, direction) => {
  hoverOn.classList.add('expandCard', styles['expandCard' + direction])
  if (bigCard) bigCard.classList.add('collapseCard', styles['collapseCard' + (direction === 'Left' ? 'Right' : 'Left')])
}

const cardLeaveHover = () => {
  ['collapseCardLeft', 'collapseCardRight', 'expandCardLeft', 'expandCardRight', 'movementRight', 'movementLeft'].forEach((className) => {
    document.querySelectorAll(`.${styles[className]}`).forEach((el) => el.classList.remove(styles[className]))
  })

  document.querySelectorAll('.expandCard').forEach((el) => el.classList.remove('expandCard'))
}

const CompanyItem = ({ company, className, maxWidth, uniqId, idx }) => {
  const notSmallCards = className.includes(styles.mediumCard) || className.includes(styles.bigCard)

  const linkContent = (
    <Link
      to={`/companies/${company.slug.current}`}
      className={cn(styles.companyItem, className.includes(styles.smallCard) ? styles.smallCard : null)}
      style={{ background: company.brandGradient?.useGradient ? createGradientBackground(company.brandGradient) : company.brandHex?.hex || null }}
    >
      {(company.logo || company.logoColor) && (
        <Image
          imgObj={company.chooseLogo && company.logoColor ? company.logoColor : company.logo}
          props={{
            alt: company.title,
            width: '362'
          }}
          className={styles.companyImage}
        />
      )}

      {company.mainImage && (
        <Image
          imgObj={company.mainImage}
          props={{
            alt: company.title,
            width: maxWidth || null,
            height: '505'
          }}
          className={styles.companyImageHover}
        />
      )}
    </Link>
  )

  return notSmallCards
    ? <div
        className={
        cn(className,
          className.includes(styles.mediumCard) ? idx === 1 ? 'mediumCard' : 'mediumCard' : null,
          className.includes(styles.bigCard) ? 'bigCard' : null
      )
      }
        data-idx={idx}
        data-uniq-id={uniqId}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={cardLeaveHover}
    >{linkContent}
    </div> : linkContent
}

const CompanyItemList = ({ company, uniqId, idx }) => {
  return (
    <div data-idx={idx} data-uniq-id={uniqId} className={styles.listItem}>
      <Link to={`/companies/${company.slug.current}`} className={styles.listLink}>
        <span className={styles.companyTitle}>{company.title}</span>
        <span className={styles.companySector}>
          {company?.sectors && company.sectors
            .filter((sector) => sector.title)
            .map((sector, index, array) => (
              <span
                key={index}
                style={{
                  marginRight: index === array.length - 1 ? '16px' : '0'
                }}
              >
                {sector.title}
                {index < array.length - 1 && ', '}
              </span>
            ))}
        </span>
        <span className={styles.companyStage}>
          {company?.stage && company.stage[0] && (
            <span>{company.stage[0].title}</span>
          )}
        </span>
        <span className={styles.companyLink} onClick={(event) => event.stopPropagation()}>
          <a href={company.link} target='_blank' rel='noopener noreferrer'>
            {company.link ? (
              <a href={company.link} target='_blank' rel='noopener noreferrer'>
                {company.link
                  .replace(/^(https?:\/\/)?(www\.)?/, '')
                  .replace(/\/$/, '')}
              </a>
            ) : (
              <span>No link available</span>
            )}
          </a>
        </span>
        <span className={styles.companyArrowIcon} />
      </Link>
    </div>
  )
}

const groupCompanies = (companies, calcToRender) => {
  if (!companies || companies.length === 0) return []

  const groups = []
  let i = 0

  while (i < companies.length) {
    const remaining = companies.length - i

    const pageSize = Math.min(remaining, calcToRender)
    groups.push(companies.slice(i, i + pageSize))
    i += pageSize
  }

  return groups
}

const renderChunk = (chunk, calcToRender, chunkIdx, isListView) => {
  const onlyOneCard = chunk.length === 1

  const patterns = {
    12: {
      bigCard: [0, 6],
      mediumCard: [1, 4, 5, 7, 8, 11],
      companiesGridVertical: [2, 9]
    },
    15: {
      bigCard: [0, 6, 7, 8, 11, 13, 14, 15, 16],
      mediumCard: [1, 3, 4, 5, 10, 12],
      companiesGridVertical: [2, 9]
    },
    17: {
      bigCard: [0, 6, 7, 8, 11, 13, 14, 15, 16],
      mediumCard: [1, 3, 4, 5, 10, 12],
      companiesGridVertical: [2, 9]
    }
  }

  const getCardClass = (idx) => {
    for (const [cardType, items] of Object.entries(patterns[calcToRender])) {
      if (items.includes(idx)) {
        return styles[cardType]
      }
    }
    return styles.smallCard
  }

  const renderCard = (company, idx) => {
    const className = getCardClass(idx)
    const uniqId = chunkIdx === 0 ? idx : idx + calcToRender

    if (className === styles.companiesGridVertical) {
      return renderVerticalCard(company, idx, uniqId)
    }

    return (
      <CompanyItem
        idx={idx}
        uniqId={uniqId}
        company={company}
        className={cn(styles.card, className, onlyOneCard && styles.centeredCard)}
      />
    )
  }

  const renderVerticalCard = (company, idx, uniqId) => {
    if (!chunk[idx + 1]) {
      return (
        <CompanyItem
          idx={idx}
          uniqId={uniqId}
          company={company}
          className={styles.mediumCard}
        />
      )
    }

    return (
      <div className={cn(styles.companiesGridVertical, styles.mediumCard)}>
        <CompanyItem uniqId={uniqId} company={company} className={styles.smallCard} />
        <CompanyItem uniqId={uniqId + 1} company={chunk[idx + 1]} className={styles.smallCard} />
      </div>
    )
  }

  const renderListCard = (company, idx, uniqId) => (
    <CompanyItemList idx={idx} uniqId={uniqId} company={company} />
  )

  const usedIndexes = new Set()

  return chunk.map((company, idx) => {
    if (usedIndexes.has(idx)) return null

    if (getCardClass(idx) === styles.companiesGridVertical && chunk[idx + 1] && !isListView) {
      usedIndexes.add(idx + 1)
    }

    return isListView ? renderListCard(company, idx, chunkIdx) : renderCard(company, idx)
  })
}

const CompaniesGrid = ({ companies, companiesToRender, calcToRender, handleLoadMore, switchToListView, handleLoadAll, buttonName, page, isListView }) => {
  const [companiesChunks, setCompaniesChunks] = useState([])

  useEffect(() => {
    const maxCompanies = companies.filter((company, idx) => idx < companiesToRender)
    const chunks = groupCompanies(maxCompanies, calcToRender)
    setCompaniesChunks(chunks)
  }, [companiesToRender, companies])

  return (
    <section id='companies-section' className={styles.companiesContainer} style={{ marginBottom: 56 }}>
      {companiesChunks.length > 0 ? (
        <div className={isListView ? styles.companiesListWrap : styles.companiesGridWrap}>
          {companiesChunks.map((chunk, idx) =>
            renderChunk(chunk, calcToRender, idx, isListView)
          )}
        </div>
      ) : (
        <p className={styles.noResults}>No results found</p>
      )}

      {companies.length > companiesToRender && (
        <div
          style={buttonName ? {
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: 12,
            marginBottom: 0
          } : {}} className={styles.viewMoreWrap}
        >
          {page === 'home'
            ? <Link style={{ color: '#2F2F2F' }} to='/companies' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>
              View all
              <span>
                <div className={linkStyle.iconCircle} />
                <div className={`${linkStyle.icon} ${styles.icon}`}>
                  <Icon symbol='newsArrowIcon' color='currentColor' />
                </div>
              </span>
              </Link>
            : <>
              <a className={`${linkStyle.linkPlus} ${linkStyle.notIcon}`} onClick={handleLoadMore}>{buttonName || 'Load more'}</a>
              <span className={styles.symbolSlash}>/</span>
              <a className={`${linkStyle.linkPlus} ${linkStyle.notIcon}`} onClick={handleLoadAll}>{buttonName || 'Load All'}</a>
              </>}
        </div>
      )}
      {!isListView
        ? <div className={styles.viewMoreWrap}>
          <>
            <a
              className={`${linkStyle.linkPlus} ${linkStyle.notIcon}`}
              onClick={switchToListView}
            >{'View More'}
            </a>
          </>
          </div>
        : ''}
    </section>
  )
}

export default CompaniesGrid
